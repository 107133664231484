import ButtonPractice from "./components/ButtonPractice/ButtonPractice";

function App() {
  return (
    <div className="App">
      <ButtonPractice />
    </div>
  );
}

export default App;
