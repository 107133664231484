import React, { useReducer } from "react";

const inisialState = {
  count: 0,
};

function buttonHandler(state, action) {
  switch (action.type) {
    case "ACTION_TYPE_MINUS":
      return { count: state.count - 1 };
    case "ACTION_TYPE_RESET":
        alert("خیلی باهام ور رفتی بسه")
      return { count: 0 };
    case "ACTION_TYPE_PLUS":
      return { count: state.count + 1 };
    default:
      return state;
  }
}

export default function ButtonPractice() {
  const [state, dispatch] = useReducer(buttonHandler, inisialState);

  return (
    <div className="fixed inset-0 flex gap-4 justify-center items-center flex-col w-screen h-screen bg-slate-900   *:text-slate-900 *:rounded-2xl ">
      <span className="p-5 bg-slate-400 font-bold text-xl text-center">
        🔞 میتونی با دکمه هام بازی کنی 
      </span>
      <div className="flex gap-4 *:bg-slate-400 *:size-12 *:rounded-xl *:grid *:place-content-center active:*:bg-slate-700 active:*:text-slate-200 *:transition ">
        <button onClick={() => dispatch({ type: "ACTION_TYPE_PLUS" })}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </button>

        <button onClick={() => dispatch({ type: "ACTION_TYPE_RESET" })}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6 font-bold">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        </button>

        <button onClick={() => dispatch({ type: "ACTION_TYPE_MINUS" })}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
          </svg>
        </button>
      </div>

      <span className="size-16 bg-slate-400 font-bold grid place-content-center text-2xl">{state.count}</span>
    </div>
  );
}
